<template>
  <div id="merchant-page">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Sales')}}</h2>
      <span class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="openSalesModal('add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="sales"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Email')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>
          <shipblu-td :data="data[indextr].name" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].first_name }} {{ data[indextr].last_name }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-3" :data="data[indextr].email">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Email')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].email }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].phone_number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone Number')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].phone_number }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">

                  <!-- ACTIONS FOR ON CREATED -->
                  <vs-dropdown-item
                    @click="openSalesModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="resetPassword(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Change Password')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="confirmDelete(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <sales :type="type" :data="salesperson" :addUserModal="addUserModal"
    @addUserModal="addUserModal = $event" @loadData="loadData"></sales>
    <reset-password userRole="sales" :resetPass="resetPass" :userData="userData" @loadData="loadData" @resetPass="resetPass = $event"></reset-password>
  </div>
</template>

<script>
import Sales from './components/AddSales.vue'
import { sendRequest }  from '../../http/axios/requestHelper.js'
import ResetPassword from './components/ResetPassword.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      salesURL: 'api/v1/users/sales/',
      selected: [],
      sales: [],
      addUserModal: false,
      type: '',
      resetPass: false,
      salesperson: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      userData: {},
      deleteData: {},
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: 'admin-management-sales',
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadData()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadSalesSearch)
    },
    loadSalesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `${this.salesURL}${query}`, 'get', null, true,
        (response) => {
          this.sales = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    openSalesModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.salesperson = Object.assign({}, {})
      } else {
        this.salesperson = data
      }
      this.addUserModal = true
    },
    confirmDelete (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.deleteFinance
      })
    },
    deleteFinance () {
      sendRequest(false, false, this, `${this.salesURL}${this.deleteData.uid}`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Sales account'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    resetPassword (data) {
      this.resetPass = true
      this.userData = data
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadData()
  },
  components: {
    ResetPassword,
    Sales,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
